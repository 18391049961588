
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Newsitem } from '@/repository/kontent/stores/newsStore';
import languageCodes, { formatDate } from '@/repository/kontent/utilities/languageCodes';
import { LabelCssVal } from '@/_ns_modules/src/ts/labelCssUtil';
import I18n from '@/i18n/index';
const assetsUrl = process.env.VUE_APP_ASSETS_URL;
const newsDefaultFile = assetsUrl ? assetsUrl + '64fe2630-2ddd-450c-bec8-4cf189e227ad/news_default.png' : '';

@Component
export default class ListItem extends Vue {
  @Prop(Object) news!: Newsitem;

  private i18n = I18n;
  private get date() {
    return formatDate(this.news.date.value, I18n.language);
  }
  private get thumbnail() {
    const thumbnailArr = this.news.thumbnail?.value;
    return thumbnailArr.length > 0 ? thumbnailArr[0].url : newsDefaultFile;
  }
  private get newsGroupVal() {
    const newsGroupArr = this.news.newsGroup.value;
    return newsGroupArr.length > 0 ? newsGroupArr[0] : null;
  }
  private get groupVal() {
    return this.newsGroupVal?.codename ?? 'other';
  }
  private get groupClass() {
    const labelVal = this.newsGroupVal?.codename ?? 'other';
    return LabelCssVal[labelVal];
  }
  private get year() {
    return this.news.date.value?.getFullYear() ?? '';
  }
}
