import Vue from 'vue';
import { reactive } from '@vue/composition-api'
import  Store from './base/store';
import { Newsitem } from "../models/newsitem";
import { SortOrder, ElementModels } from '@kentico/kontent-delivery';
import dateFormat from 'dateformat';

class NewsStore extends Store<Newsitem>{

  constructor(){
    super('newsitem')
  }
  public getArticle(code:string) {
    return this
      ._i18nedList.filter(x => x.url.value === code).find(x => x);
  }

  public getYearArr(desc?:Boolean){
    const rawYearArr = this._i18nedList
      .map(x => x.date.value?.getFullYear())
      .filter(x => x != null) 
    const filterYearArr = rawYearArr.filter((item): item is number => typeof item == 'number')
    return Array.from(new Set(filterYearArr)).sort((a, b) => {
      return desc ? b - a : a - b
    })
  }


  public getLatestYear(){
    return Math.max.apply(null, this.getYearArr())
  }
  
  public setNewsGroup(value:string){
    this.setTaxonomyFilter('elements.news_group', [value])
  }

  public setNewsGroupEmpty(){
    this._query.emptyFilter('elements.news_group')
  }

  public setTaxonomyFilter(key:string, values:string[]){
    this._query.containsFilter(key, values);
  }

  public hasNext() : boolean{
    return this._pagination?.nextPage !== ""
  }

  public getNextUrl() {
    return this._pagination?.nextPage
  }

  public setYear(year:number|string|undefined)
  {
    if(!year)return
    if( typeof year == 'string') year = parseInt(year) 
    const targetYearMinFormat = dateFormat(new Date(year, 0, 1), 'yyyy-mm-dd');
    const targetYearMaxFormat = dateFormat(new Date(year + 1, 0, 1), 'yyyy-mm-dd');
    this.setRangeFilter('elements.Date', targetYearMinFormat, targetYearMaxFormat);

  }
}

const x = new NewsStore();
x.setOrderParameter("elements.date", SortOrder.desc);
x.restore();

export { Newsitem, NewsStore };
export default reactive(x);
