





































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { reactive } from '@vue/composition-api';
import { Newsitem, NewsStore } from '@/repository/kontent/stores/newsStore';
import { Client } from '@/repository/kontent/stores/base/client';
import { SortOrder, TaxonomyTerms } from '@kentico/kontent-delivery';
import ListItem from './ListItem.vue';
import I18n, { Languages } from '@/i18n/index';

const DISPLAY_NUMBER_LIMIT = 8;

@Component({
  components: {
    ListItem,
  },
})
export default class Index extends Vue {
  private i18n = I18n;
  public async created() {
    const result = await Client.taxonomy('news_group').toPromise();
    this.groupArr = result.taxonomy.terms;
    this.groupArr.unshift(this.groupDefault[I18n.language]);
    await this.getNewsList();
  }
  private targetLang = this.$route.params.language as Languages;

  // 検索条件用
  private filterData = reactive(new NewsStore());
  // 検索結果用
  private wholeData = reactive(new NewsStore());
  private yearGroup = this.wholeData.getYearArr();
  private groupDefault: { [index: string]: TaxonomyTerms } = {
    ja: { codename: 'all', name: 'すべて', terms: [] },
    en: { codename: 'all', name: 'all', terms: [] },
  };
  private groupSelect: TaxonomyTerms | null = null;
  private evacAjdustPosition = 0;
  private _newsList: Array<Newsitem> = [];
  private groupArr: Array<TaxonomyTerms> = [];

  @Watch('$route')
  onRouteChanged(to: any): void {
    this.getNewsList();
  }

  private get newsList() {
    const storeNews = this.filterData.list as Array<Newsitem>;
    if (!this._newsList) {
      this._newsList = storeNews;
      return this._newsList;
    }
    const storeUrls = storeNews?.map((o) => o.url.value);
    const listUrls = this._newsList?.map((o) => o.url.value);
    if (storeUrls.toString() == listUrls.toString()) {
      this._newsList = storeNews;
      return this._newsList;
    }
    this.evacAjdustPosition = window.scrollY;
    this._newsList = this._newsList.concat(storeNews);
    return this._newsList;
  }

  private get yearSelect(): string {
    return this.$route.params.year ?? this.latestYear;
  }

  private set yearSelect(v: string) {
    this.$router.push({ name: 'NewsListFilter', params: { year: v } });
  }

  private get latestYear(): string {
    return this.wholeData.getLatestYear().toString();
  }

  private changeGroup() {
    this.getNewsList();
  }

  private get hasNext() {
    return this.filterData.hasNext();
  }

  private async getNewsList() {
    this._newsList = [];

    this.groupSelect = this.groupSelect ?? this.groupDefault[I18n.language];
    const groupCode = this.groupSelect?.codename || this.groupArr[0].codename;
    this.wholeData.initQuery();
    this.wholeData.setElementsParameter(['date', 'news_group']);
    await this.wholeData.restore();
    if (!Number.isInteger(Number(this.yearSelect))) {
      this.$router.push(`/${this.i18n.language}/`);
    }
    this.filterData.initQuery();
    this.filterData.setOrderParameter('elements.date', SortOrder.desc);
    this.filterData.setLimitSkip(DISPLAY_NUMBER_LIMIT, 0);
    this.filterData.setYear(this.yearSelect);
    if (groupCode && groupCode !== 'all') {
      this.filterData.setNewsGroup(groupCode);
    }
    if (this.i18n.language !== 'ja') {
      this.filterData.setFilter('system.language', this.i18n.language);
    }
    await this.filterData.restore();
    if (this.newsList.length < 1 && groupCode == 'all') {
      this.$router.push(`/${this.i18n.language}/`);
    }
  }

  private moreNewsList() {
    const nextUrl = this.nextUrl;
    this.filterData.initQuery();
    this.filterData.setDirectUrl(nextUrl ?? '');
    this.filterData.restore();
  }

  private async getNewsGroup() {
    const result = await Client.taxonomy('news_group').toPromise();
    const groups = result.taxonomy.terms;
    groups.unshift(this.groupDefault[I18n.language]);
  }

  private get nextUrl() {
    return this.filterData.getNextUrl();
  }

  updated(): void {
    if (this.evacAjdustPosition) {
      window.scrollTo({
        top: this.evacAjdustPosition,
      });
      this.evacAjdustPosition = 0;
    }
  }
}
